import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Main from './routes';
import * as serviceWorker from './serviceWorker';
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import { bestLanguage } from "./utils/LanguageUtils";


const lang = bestLanguage(true, window.location.origin.split('.').pop(), ["en", "tr", "fr", "de", "es", "it", "fa", "ar", "ro", "zh"])

function loadResource(lang) {
  return {
    app: require(`./translations/${lang}/app.json`),
    common: require(`./translations/${lang}/common.json`),
    booking: require(`./translations/en/booking.json`) // TODO: translate
  }
}

i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: lang,
  resources: {
    'en': loadResource('en'),
    'fr': loadResource('fr'),
    'de': loadResource('de'),
    'zh': loadResource('zh'),
    'it': loadResource('it'),
    'fa': loadResource('fa'),
    'ar': loadResource('ar'),
    'es': loadResource('es'),
    'ro': loadResource('ro'),
    'tr': loadResource('tr')
  },
  fallbackLng: {
    'default': ['en']
  }
});


ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <Main />
  </I18nextProvider>, document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
