import React, { Component, useState } from 'react'
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import Collapsible from 'react-collapsible';
import { withTranslation } from "react-i18next";
import { config } from '../Constants';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { InView } from 'react-intersection-observer';
import { ReactComponent as ReceiptSVG } from '../data/icons/receipt.svg';
import { ReactComponent as InfoSVG } from '../data/icons/info.svg';
import { formattedPrice } from '../utils/misc'
import AddIcon from '@mui/icons-material/Add';
import qs from "qs";
import './MenuPage.css';
import { v4 as uuidv4 } from 'uuid';
import html2canvas from 'html2canvas'

const StarrySkyTheme = React.lazy(() => import('../specialeffects/sky'));
const FallingSnowTheme = React.lazy(() => import('../specialeffects/snow'));
const BakeryTheme = React.lazy(() => import('../specialeffects/bakery'));

const onLaptop = (window.innerWidth >= 1281);

function nItemsOpenOrder() {
	const openOrder = JSON.parse(window.localStorage.getItem("openOrder"))

	if (openOrder && openOrder.items) return openOrder.items.reduce((total, ele) => total + ele.quantity, 0)
	return 0
}

function BubbleNumber({ number, variant }) {
	return <div style={{
		position: "absolute",
		bottom: "-0.5rem",
		right: "-0.5rem",
		background: "#ffb321",
		width: "1.25rem",
		height: "1.25rem",
		borderRadius: "50%",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		border: "1px solid orange"
	}}>
		<b>{number}</b>
	</div>
}

class MenuPage extends Component {

	constructor(props) {
		super(props);

		var menuId = (props.menuId && props.menuId in props.metadata.menus) ? props.menuId : props.metadata.activeMenu  // else is active-menu
		this.tableN = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).table;

		this.state = {
			selectedLanguage: "",
			menuData: undefined,
			menuId: menuId,
			metadata: props.metadata,
			showOrders: props.ordersOpen,
			timeoutId: null
		};

		window.addEventListener("message", (event) => {
			if (event.origin.startsWith("https://admin.takeaseat.io") || event.origin.startsWith("http://localhost:3000") || event.origin.endsWith(".tas.ink")) {
				if (event.data.nature === "metadata") this.setState({ metadata: event.data.content })
				if (event.data.nature === "menuData") this.setState({ menuData: event.data.content })
				if (event.data.nature === "scs") {
					const screenshotTarget = document.body;

					html2canvas(screenshotTarget, {
						allowTaint: true,
						useCORS: true,
						scale: 1.75
					}).then((canvas) => {
						const base64image = canvas.toDataURL("image/png");
						/*
						var a = document.createElement("a"); //Create <a>
						a.href = base64image
						a.download = "Image.png"; //File name Here
						a.click(); //Downloaded file*/
						event.source.postMessage(base64image, event.origin);
					});
				}
			} else {
				return;
			}
		});

		this.loadData = this.loadMenu.bind(this);
		this.handleLanguageChange = this.handleLanguageChange.bind(this);
		this.getMostAppropriateLanguage = this.getMostAppropriateLanguage.bind(this);
		this.toggleOrderView = this.toggleOrderView.bind(this)
	}

	toggleOrderView() {
		this.setState({ showOrders: !this.state.showOrders })
	}

	componentDidMount() {
		this.loadMenu(this.getMostAppropriateLanguage(), data => {
			const reducedMenu = []

			var nItems = 0

			data.forEach((section, s) => {
				if (nItems >= 5) {
					reducedMenu.push(section)
					reducedMenu[s].items = []
					reducedMenu[s].subsections = []
					return
				}

				reducedMenu.push(section)

				nItems += section.items.length

				if (nItems >= 5) {
					reducedMenu[s].items = reducedMenu[s].items.splice(0, 5)
					reducedMenu[s].subsections = []
				}

				if (reducedMenu[s].subsections.length > 0) nItems += reducedMenu[s].subsections.reduce((total, ss) => total + ss.items.length)
			})

			this.setState({ menuData: reducedMenu })
		});
	}

	loadMenu(lang, callback) {
		fetch(`${config.url.PUBLIC}/restaurants/${this.props.restaurantId}/menus/${this.state.menuId}/${lang}/menu.json`, {
			method: 'get',
		}).then(data => data.json())
			.then(data => callback(data))
	}

	getMostAppropriateLanguage() {
		var qrcodeLang = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).lang;

		var lang = "";
		if (this.state.selectedLanguage !== "") {
			lang = this.state.selectedLanguage;
		} else if (qrcodeLang) {
			lang = qrcodeLang
		} else if ("language" in this.props.location) {
			lang = this.props.location.language;
		} else if ("language" in navigator && navigator.language !== "") {
			lang = navigator.language.substr(0, 2);
		} else {
			lang = "en";
		}

		if (!this.state.metadata.menus[this.state.menuId].supportedLanguages.some(sl => sl.code === lang)) {
			lang = this.state.metadata.menus[this.state.menuId].supportedLanguages[0].code;
		}
		return lang;
	}

	handleLanguageChange(lang) {
		this.setState(
			{ selectedLanguage: lang },
			() => this.loadMenu(
				this.getMostAppropriateLanguage(),
				data => {
					this.setState({ menuData: data, menuLang: lang });
				})
		);
	}

	takeScs() {
		html2canvas(document.body, {
			allowTaint: true,
			useCORS: true
		}).then((canvas) => {
			const base64image = canvas.toDataURL("image/png");
			var a = document.createElement("a"); //Create <a>
			a.href = base64image
			a.download = "Image.png"; //File name Here
			a.click(); //Downloaded file
		});
	}

	render() {
		const mLKey = (() => {
			if (this.state.metadata.logo && this.state.metadata.logo.src) return this.state.metadata.logo.src + (this.state.menuLang || "")
			return "metadata-logo" + (this.state.menuLang || "")
		})()

		if (this.state.menuData === undefined) {
			return <></>
		}

		if (this.state.menuData === null) {
			return <div className="menu-page"></div >
		}

		return <div className={"menu-page " + (this.state.metadata.specialEffects || "")}>
			{(this.state.metadata.specialEffects && this.state.metadata.specialEffects.includes('falling-snow')) && <React.Suspense fallback={<></>}><FallingSnowTheme /></React.Suspense>}
			{(this.state.metadata.specialEffects && this.state.metadata.specialEffects.includes('starry-sky')) && <React.Suspense fallback={<></>}><StarrySkyTheme /></React.Suspense>}
			{(this.state.metadata.specialEffects && this.state.metadata.specialEffects.includes('bakery')) && <React.Suspense fallback={<></>}><BakeryTheme /></React.Suspense>}

			<MenuList {...this.props}
				key={mLKey}
				sections={this.state.menuData}
				metadata={this.state.metadata}
				supportedLanguages={this.state.metadata.menus[this.state.menuId].supportedLanguages}
				language={this.getMostAppropriateLanguage()}
				onLanguageChange={this.handleLanguageChange}
				toggleOrderView={this.toggleOrderView}
				menuId={this.state.menuId} />
			{
				(!this.props.metadata.ordering && (this.state.metadata.include_watermark === undefined || this.state.metadata.include_watermark)) && <div
					className={"watermark" + (onLaptop ? " lg-screen" : "")}
					style={(this.state.metadata.enableFeedback && this.state.menuData.length > 0) ? { marginTop: "0" } : {}}>
					<a href="https://www.takeaseat.io" target="_blank">Created with www.takeaseat.io</a>
				</div>
			}
		</div >;
	}
}


function ListLabels({ labels, isSet, toggle, childkey }) {
	return <div className={"labels"}>
		{labels.map((label, i) => (
			<div className={"label clickable"} key={`${childkey}-${i}`} onClick={() => toggle(label)}>
				{label}{i < (labels.length - 1) && ','}
			</div>
		))}
	</div>
}

function ListIcons({ icons, isSet, toggle, childkey }) {
	return <div className="icons">
		{icons.map((icon, i) => (
			<img src={`${config.url.PUBLIC}/icons/${icon.replace('.svg', '.png')}`} className='icon clickable' width="24" height="24" key={`${childkey}-${i}`} onClick={() => toggle(icon)} />
		))}
	</div>
}

function atLeastOneOption(options) {
	var result = false;
	options.forEach((option) => {
		result = result || option.prefix || option.amount || option.suffix
	})
	return result
}

const LazyCarrousel = ({ images, restaurantId, item, size }) => {
	const [imageInView, setImageInView] = useState(0)

	return <div style={{ position: "relative", display: "flex" }}>
		<div className="carrousel-content" style={images.length === 1 ? { justifyContent: "center", paddingInline: "0" } : {}}>
			{images.map((_, i) => <LazyPicture
				key={images[i]}
				size={size}
				visible={i < imageInView + 3}
				src={`${config.url.PUBLIC}/restaurants/${restaurantId}/images/${images[i]}`}
				alt={item.name}
				inViewCallback={() => setImageInView(i)} />)}
		</div>
	</div>
}

const LazyPicture = ({ src, alt, visible, size, inViewCallback }) => {
	const [load, setLoad] = useState(false)
	const [fullscreen, setFullscreen] = useState(false)

	return <InView
		rootMargin={"0px 10px 750px 10px"}
		delay={0}
		style={{ display: (visible ? "" : "none") }}
		className={"lazy-image-container"}
		as="div"
		onClick={() => setFullscreen(prev => !prev)}
		threshold={0}
		triggerOnce={false}
		onChange={(inView, entry) => {
			if (inView) setLoad(inView)
			if (entry.isIntersecting) inViewCallback()
		}}>
		{load ? <img
			src={`${encodeURI(src)}`}
			className={"lazy-image" + (size === "small" ? " small" : "")}
			alt={alt} /> : <div className="lazy-image-placeholder"><div>Loading...</div></div>}
		{fullscreen && <div className='fullscreen'>
			<img
				src={`${encodeURI(src)}`}
				className={"lazy-image"}
				alt={alt} />
		</div>}
	</InView>
}

const Item = React.memo(function Item({ item, restaurantId, filterIsSet, toggleFilter, t, orderCallback, toggleOrderView }) {
	// TODO: remove react memo?
	var prices = [];
	item.price.forEach((p, i) => prices.push(<li key={`${item.id}-price-${i}`}>{formattedPrice(p)}</li>))

	const images = item.images || []

	return (
		<li name={item.id || ""}>
			<div className={"item " + (orderCallback ? "w-order" : "")} style={{ marginBottom: "1.4rem" }}>
				<table style={{ width: "100%", marginBottom: "0" }}>
					<tbody onClick={() => orderCallback ? orderCallback(item) : null}>
						<tr>
							<td className={"name"}>
								<div className="item-name" style={{ position: "relative" }}>
									{item.name}
								</div>
								<ListLabels childkey={item.id + "-labels"} labels={item.labels} isSet={filterIsSet} toggle={toggleFilter} />
								<ListIcons childkey={item.id + "-icons"} icons={item.icons || []} isSet={filterIsSet} toggle={toggleFilter} />
							</td>
							{(item.price.length > 0) && <td className={"price"} >
								<ul>{prices}</ul>
							</td>}
						</tr>
						{item.description &&
							<tr>
								<td colSpan="2" style={{ position: "relative" }}>
									<div className={"description"}>{item.description}</div>
								</td>
							</tr>
						}
					</tbody>
				</table>
				{(images.length > 0) && <div style={{ marginTop: "0.5rem" }}>
					<LazyCarrousel item={item} restaurantId={restaurantId} images={images} orderCallback={orderCallback} toggleOrderView={toggleOrderView} />
				</div>}
				{atLeastOneOption(item.options) && <div style={{ position: "relative", margin: "0.5rem 0.25rem 0 0.25rem" }} onClick={() => orderCallback ? orderCallback(item) : null}>
					<div style={{ display: "flex", marginTop: "0.5rem" }}>
						<div style={{ fontWeight: "bold" }}>{item['options-label'] || t('options')}:</div>
						<ul className={"options"}>
							{item.options.map((option, i) =>
								<li key={`${item.id}-option-${i}`}>{formattedPrice(option)}</li>
							)}
						</ul>
					</div>
				</div>}
				{(item.co?.length > 0) && <div style={{ position: "relative", margin: "0.5rem 0.25rem 0 0.25rem" }} onClick={() => orderCallback ? orderCallback(item) : null}>
					<div style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}>
						<div style={{ fontWeight: "bold" }}>{t('customizations.title')}</div>
						<ul className={"options"}>
							{item.co?.map((customization, co) => {
								return <li key={`${item.id}-co-${co}`} className="customization">{customization.options.map((option, o) => {
									return <>
										{(o > 0) && <span> or </span>}
										<span key={`${item.id}-co-${co}-${o}`}>{option.label || ""}</span>
									</>
								})}</li>
							})}
						</ul>
					</div>
				</div>}
				{(("notes" in item) && (item.notes !== "")) && <div style={{ position: "relative", margin: "0.5rem 0.25rem 0 0.25rem" }} onClick={() => orderCallback ? orderCallback(item) : null}>
					<div className={"notes"}>{item.notes}</div>
				</div>}
				{orderCallback && <div className="order-button" onClick={() => orderCallback(item)}>
					<span><AddIcon /></span>
				</div>}
			</div>
		</li>
	);
})

function isEmptyItem(item) {
	return (
		item.name === "" &&
		item.description === "" &&
		item.price.length === 0 &&
		item.labels.length === 0 &&
		item.options.length === 0 &&
		item.notes === ""
	)
}

function scrollToId({ id }) {
	try {
		const ele = document.getElementById(id)
		if (ele) ele.scrollIntoView({ behavior: "auto", block: "nearest", inline: "center" })
	} catch (e) { }
}


function scrollToName({ name }) {
	try {
		const ele = document.getElementsByName(name)
		if (ele[0]) ele[0].scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" })
	} catch (e) { }
}

function Subsection({ subsection, section, restaurantId, t, filterIsSet, toggleFilter, orderCallback, toggleOrderView, defaultCollapsed }) {
	const images = subsection.images || []

	return (
		<li>
			<Collapsible
				trigger={
					<div className="title clickable">
						<div style={{ display: "flex", margin: "auto", position: "relative" }}>
							{(subsection.name) ? <>
								<h3>{subsection.name}</h3>
								<div className="section-collapser"><ExpandLessIcon /></div>
							</> : <div><div className="section-collapser"><ExpandLessIcon /></div></div>}
						</div>
						<a id={'s' + section.id + "-" + subsection.id} className="sanchor"></a>
						<a id={section.id + "-" + subsection.id} className="anchor"></a>
					</div>
				}
				open={!defaultCollapsed || !subsection.name}>
				{(images.length > 0) && <LazyCarrousel item={subsection} restaurantId={restaurantId} images={images} size={"small"} toggleOrderView={toggleOrderView} />}
				{subsection.description ? (<div className={"section-description"}>
					<div><InfoSVG name="custom-svg" height="0.8em" width="0.8em" /> </div>
					<div>{subsection.description}</div>
				</div>) : null}
				<ul className={"items"}>
					{subsection.items.map((item, index) => (
						(!isEmptyItem(item) && (!item.hide)) && <Item key={item.id} t={t} item={item} restaurantId={restaurantId} filterIsSet={filterIsSet} toggleFilter={toggleFilter} orderCallback={orderCallback} toggleOrderView={toggleOrderView} />
					))}
				</ul>
				{subsection.footnote ? (<div className={"footnote"}>
					<div><InfoSVG name="custom-svg" height="0.8em" width="0.8em" /> </div>
					<div>{subsection.footnote}</div>
				</div>) : null}
			</Collapsible>
		</li>
	)
}


function Section({ template, section, t, restaurantId, filterIsSet, toggleFilter, orderCallback, toggleOrderView, defaultCollapsed }) {
	const images = section.images || []

	return <Collapsible trigger={
		<div className="title clickable">
			<div style={{ display: "flex", margin: "auto", position: "relative" }}>
				{(section.name) ? <>
					<h2>{section.name}</h2>
					<div className="section-collapser"><ExpandLessIcon /></div>
				</> : <div style={{ margin: "2rem 0" }}><div className="section-collapser"><ExpandLessIcon /></div></div>}
				<a id={'s' + section.id} className="sanchor"></a>
			</div>
		</div>
	} open={(!defaultCollapsed) || template === 2}>
		<a id={section.id} className="anchor"></a>
		{(images.length > 0) && <LazyCarrousel item={section} restaurantId={restaurantId} images={images} size={"small"} toggleOrderView={toggleOrderView} />}
		{section.description ? (<div className={"section-description"}>
			<div><InfoSVG name="custom-svg" height="0.8em" width="0.8em" /></div>
			<div> {section.description}</div>
		</div>) : null}
		<ul className={"items"}>
			{section.items.map((item, index) => (
				(!isEmptyItem(item) && (!item.hide)) && <Item key={item.id} t={t} item={item} restaurantId={restaurantId} filterIsSet={filterIsSet} toggleFilter={toggleFilter} orderCallback={orderCallback} toggleOrderView={toggleOrderView} />
			))}
		</ul>
		<ul className={"subsections"}>
			{section.subsections.map(subsection => (
				(!subsection.hide) && <Subsection defaultCollapsed={defaultCollapsed} key={subsection.id} t={t} subsection={subsection} section={section} restaurantId={restaurantId} filterIsSet={filterIsSet} toggleFilter={toggleFilter} orderCallback={orderCallback} toggleOrderView={toggleOrderView} />
			))}
		</ul>
		{section.footnote ? (<div className={"footnote"}>
			<div><InfoSVG name="custom-svg" height="0.8em" width="0.8em" /></div>
			<div> {section.footnote}</div>
		</div>) : null}
	</Collapsible>
};

const ListSections = ({ template, selectedSection, sections, t, restaurantId, toggleFilter, filterIsSet, orderCallback, toggleOrderView, defaultCollapsed }) => (
	<div className={"sections " + (template === 2 ? " template-2" : "")}>
		{sections.map(section => (
			(!section.hide) && <div className={section.id === selectedSection ? "selected" : ""}>
				<Section key={section.id} template={template} defaultCollapsed={defaultCollapsed} section={section} t={t} restaurantId={restaurantId} toggleFilter={toggleFilter} filterIsSet={filterIsSet} orderCallback={orderCallback} toggleOrderView={toggleOrderView} />
			</div>
		))}
	</div>
);

class MenuList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedFilters: Object(),
			searchKey: '',
			showFilterBar: false,
			drawerState: false,
			showLogo: true,
			nItemsOpenOrder: nItemsOpenOrder(),
			itemSelected: null,
			showHighlight: true,
			selectedSection: props.sections[0]?.id || null,
			currentOrderId: uuidv4(),
			openOrder: JSON.parse(window.localStorage.getItem("openOrder")) || { items: [] }
		}
	}

	render() {
		var allLabels = []
		var chefSelection = null

		this.props.sections.forEach(section => {
			section.items.forEach(item => {
				if (item.labels) allLabels = allLabels.concat(item.labels)
				if (item.icons) allLabels = allLabels.concat(item.icons)
				if (item.cs && !item.hide && !section.hide) chefSelection = {
					id: item.id,
					name: item.name,
					description: item.description,
					sectionId: section.id
				}
			})
			section.subsections.forEach(subsection => {
				subsection.items.forEach(item => {
					if (item.labels) allLabels = allLabels.concat(item.labels)
					if (item.icons) allLabels = allLabels.concat(item.icons)
					if (item.cs && !item.hide && !section.hide && !subsection.hide) chefSelection = {
						id: item.id,
						name: item.name,
						description: item.description,
						sectionId: section.id
					}
				})
			})
		})
		allLabels = Array.from(new Set(allLabels))
		allLabels.sort()

		const { t, i18n } = this.props;

		return <div className={onLaptop ? "lg-screen" : ""}>
			<React.Fragment>
				<AppBar position="sticky" tabIndex="-1" >
					<Toolbar>
						{!onLaptop && <MenuIcon onClick={() => this.setState({ drawerState: true })} />} {/** On laptop we show drawer */}
						<div style={{ display: "flex", marginLeft: "auto", alignItems: "center" }}>
							<SearchIcon onClick={this.toggleFilterBar} />
							{this.props.metadata.ordering && <div onClick={this.props.toggleOrderView} style={{ marginLeft: "2rem", position: "relative" }}>
								<ReceiptSVG style={{ height: "1.5rem", width: "1.5rem", fill: "currentcolor" }} />
								{this.state.nItemsOpenOrder ? <BubbleNumber key={`bubble-${nItemsOpenOrder}`} number={this.state.nItemsOpenOrder} variant={"warning"} /> : null}
							</div>}
						</div>
					</Toolbar>
				</AppBar>
			</React.Fragment>
			<section>
				{(this.state.showLogo) &&
					<div style={{ textAlign: "center" }}>
						<img
							alt={this.props.metadata.logo.alt}
							style={{ objectFit: "contain", objectPosition: "center center" }}
							width="180"
							height="180"
							data-type="image"
							itemProp="image"
							src={`${config.url.PUBLIC}/restaurants/${this.props.restaurantId}/${this.props.metadata.logo.src}`}
							onError={(e) => {
								this.setState({ showLogo: false })
							}} className="menu-logo" />
					</div>
				}
				{this.props.sections.length === 0 ?
					<div>No menu found</div>
					: <> {this.props.metadata.template === 2 && <div className='selector'>{
						<div>
							{this.props.sections.map(section => {
								return <div
									name={section.id || "n"}
									className={this.state.selectedSection === section.id ? "selected" : ""}
									onClick={() => {
										this.setState({ selectedSection: section.id }, () => {
											scrollToId({ id: section.id || "n" })
											scrollToName({ name: section.id || "n" })
										})
									}}><span>{section.name}</span></div>
							})}
						</div>
					}
					</div>}
						<ListSections
							template={this.props.metadata.template}
							selectedSection={this.state.selectedSection}
							sections={this.props.sections || []}
							defaultCollapsed={this.props.metadata.c ? this.props.metadata.c : false}
							t={t}
							toggleFilter={this.toggleFilter}
							filterIsSet={this.filterIsSet}
							restaurantId={this.props.restaurantId}
							orderCallback={(this.props.metadata.ordering) ? ((item) => this.setState({ itemSelected: item, currentOrderId: uuidv4(), slideOpenWidget: true })).bind(this) : undefined}
							toggleOrderView={this.props.toggleOrderView} /> {/** TODO: remove toggle order view */}
					</>
				}
			</section>
		</div>
	}
}

const MenuPageTranslated = withTranslation('common')(MenuPage)

export default MenuPageTranslated
