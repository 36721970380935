import React, { useState } from 'react';
import { Route, Switch } from 'react-router';
import './App.css';
import { BrowserRouter } from "react-router-dom";
import { config } from './Constants';
import qs from "qs";
import MenuPage from './menu/MenuPage'

function identifier() {
	const parts = window.location.pathname.split("/")

	if (parts.length > 1) return parts[1]
	return null
}

function loadMetadata({ restaurantId }) {
	return fetch(`${config.url.PUBLIC}/restaurants/${restaurantId}/metadata.json`, {
		method: 'GET'
	})
		.then(res => res.json())
		.then(metadata => {
			// Has no active menu. This should not be the case if there is at least one menu.
			if (metadata.activeMenu === "" || metadata.menus[metadata.activeMenu] === undefined) {
				metadata.activeMenu = "default"
				metadata.menus["default"] = { "label": "Default", "supportedLanguages": [{ "name": "Anglais", "code": "en" }] }
			}

			// Have at least one supported language
			if (metadata.menus[metadata.activeMenu].supportedLanguages.length === 0) metadata.menus[metadata.activeMenu].supportedLanguages.push({ "code": "en", "name": "English" })
			return metadata
		})
		.catch(e => {
			console.error(e)
			return {}
		})
}

function getMenuId(props) {
	return qs.parse(props.location.search, { ignoreQueryPrefix: true }).menu;
}

function Main() {
	const [metadata, setMenuMetadata] = useState()

	const restaurantId = identifier()

	if (!metadata) {
		loadMetadata({ restaurantId }).then(metadata => setMenuMetadata(metadata))
		return <><div>Refresh page</div></>
	}

	return <BrowserRouter>
		<Switch>
			<Route
				path="/"
				render={(props) => {
					return <>
						<link rel="stylesheet" type="text/css" href={`${config.url.PUBLIC}/restaurants/${restaurantId}/themes/theme.css`} crossorigin="anonymous" />
						<MenuPage {...props} metadata={metadata} menuId={getMenuId(props)} page='menu' restaurantId={restaurantId} />
					</>
				}} />
		</Switch>
	</BrowserRouter>
};

export default Main;
